export default {

    revenueComparisonLine: {
        chartOptions: {
            chart: {
                toolbar: { show: false },
                dropShadow: {
                  enabled: true,
                  top: 5,
                  left: 0,
                  blur: 4,
                  opacity: 0.10,
                },
            },
            stroke: {
                curve: 'smooth',
                dashArray: [0, 8],
                width: [4, 2],
            },
            grid: {
                borderColor: '#e7e7e7',
            },
            legend: {
                show: false,
            },
            colors: ['#1e1e1e', '#F97794'],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    inverseColors: false,
                    gradientToColors: ['#F97794', '#7367F0'],
                    shadeIntensity: 1,
                    type: 'horizontal',
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                },
            },
            markers: {
                size: 0,
                hover: {
                    size: 5
                }
            },
            xaxis: {
                labels: {
                    style: {
                        cssClass: 'text-grey fill-current',
                    }
                },
                axisTicks: {
                    show: false,
                },
                categories: ['01', '05', '09', '13', '17', '21', '26', '31'],
                axisBorder: {
                    show: false,
                },
            },
            yaxis: {
                tickAmount: 5,
                labels: {
                    style: {
                        cssClass: 'text-grey fill-current',
                    },
                    formatter: function(val) {
                        return val > 999 ? (val / 1000).toFixed(1) + 'k' : val;
                    }
                }
            },
            tooltip: {
                x: { show: false }
            }
        }
    },

    systemUsersDonut: {
        chartOptions: {
            labels: ['Buyers', 'Workers', 'Supports','Admins'],
            dataLabels: {
                enabled: false
            },
            legend: { show: false },
            chart: {
                offsetY: 0,
                type: 'donut',
                toolbar: {
                    show: false
                }
            },
            stroke: { width: 0 },
            colors: ['#28c76f', '#1e1e1e','#FF9F43', '#EA5455'],
            fill: {
                type: 'gradient',
                gradient: {
                    gradientToColors: ['#42d985','#534f4f', '#FFC085', '#f29292']
                }
            }
        }
    },

    ordersDataPie: {
        chartOptions: {
            labels: ['Pending', 'Delivered', 'Completed', 'Refunded'],
            dataLabels: {
                enabled: false
            },
            legend: { show: false },
            chart: {
                type: 'pie',
                offsetY: 0,
                dropShadow: {
                    enabled: false,
                    blur: 5,
                    left: 1,
                    top: 1,
                    opacity: 0.2
                },
                toolbar: {
                    show: false
                }
            },
            stroke: {
                width: 0
            },
            colors: ['#1e1e1e', '#ff9f43', '#28c76f', '#ed6a6b'],
            fill: {
                type: 'gradient',
                gradient: {
                    gradientToColors: ['#534f4f', '#FFC085', '#42d985', '#f4a8a9']
                }
            }
        }
    },
    clientRetentionBar: {
        // series: [{
        //     name: 'New Clients',
        //     data: [175, 125, 225, 175, 160, 189, 206, 134, 159, 216, 148, 123]
        // }, {
        //     name: 'Retained Clients',
        //     data: [-144, -155, -141, -167, -122, -143, -158, -107, -126, -131, -140, -137]
        // }],
        chartOptions: {
            grid: {
                borderColor: '#ebebeb',
                padding: {
                    left: 0,
                    right: 0
                }
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false
            },
            chart: {
                stacked: true,
                type: 'bar',
                toolbar: { show: false },
            },
            colors: ['#7367F0', '#EA5455'],
            plotOptions: {
                bar: {
                    columnWidth: '10%'
                }
            },
            xaxis: {
                labels: {
                    style: {
                        cssClass: 'text-grey fill-current',
                    }
                },
                axisTicks: {
                    show: false,
                },
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                axisBorder: {
                    show: false,
                },
            },
            yaxis: {
                tickAmount: 5,
                labels: {
                    style: {
                        cssClass: 'text-grey fill-current',
                    }
                }
            },
            tooltip: {
                x: { show: false }
            },
        }
    },

    goalOverviewRadialBar: {
        chartOptions: {
              plotOptions: {
                  radialBar: {
                      size: 110,
                      startAngle: -150,
                      endAngle: 150,
                      hollow: {
                          size: '77%',
                      },
                      track: {
                          background: "#bfc5cc",
                          strokeWidth: '50%',
                      },
                      dataLabels: {
                          name: {
                              show: false
                          },
                          value: {
                              offsetY: 18,
                              color: '#99a2ac',
                              fontSize: '4rem'
                          }
                      }
                  }
              },
              colors: ['#00db89'],
              fill: {
                  type: 'gradient',
                  gradient: {
                      shade: 'dark',
                      type: 'horizontal',
                      shadeIntensity: 0.5,
                      gradientToColors: ['#1e1e1e'],
                      inverseColors: true,
                      opacityFrom: 1,
                      opacityTo: 1,
                      stops: [0, 100]
                  },
              },
              stroke: {
                  lineCap: 'round'
              },
              chart: {
                  sparkline: {
                      enabled: true,
                  },
                  dropShadow: {
                      enabled: true,
                      blur: 3,
                      left: 1,
                      top: 1,
                      opacity: 0.1
                  },
              },
        }
    },

}
